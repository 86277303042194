<template>
  <div>
    <span>Powered by</span>
    <a href="https://emilyswebsites.com" target="_blank" aria-label="Emily's Websites">
      <svg viewBox="0 0 212.84 60.91">
        <g>
          <!-- Yellow rectangle -->
          <rect
            class="svg-colour-rectangle"
            x="36.78"
            y="99.68"
            width="45.28"
            height="45.28"
            rx="6.81"
            transform="translate(-99.31 -12.76) rotate(-45)"
          />
          <!-- Rectangle highlight -->
          <rect
            class="svg-colour-image"
            x="70.69"
            y="91.41"
            width="2.44"
            height="36.85"
            rx="1.22"
            transform="translate(-86.83 -7.59) rotate(-45)"
          />
          <!-- Cursor -->
          <path
            class="svg-colour-image"
            d="M63.62,129.69l2.45,5.75a.85.85,0,0,1-.45,1.11l-3.07,1.31a.85.85,0,0,1-1.11-.45l-2.51-5.87a.84.84,0,0,0-1.31-.33l-4.9,3.91a.84.84,0,0,1-1.37-.71l1.14-23.17a.85.85,0,0,1,1.43-.58l17,15.77a.85.85,0,0,1-.49,1.47l-6.14.61A.85.85,0,0,0,63.62,129.69Z"
            transform="translate(-30.23 -90.61)"
          />
          <!-- emily's -->
          <path
            class="svg-colour-text"
            d="M116.24,107.75h-12.5c.3,3.41,1.77,4.41,3.75,4.41a3.15,3.15,0,0,0,3.37-2.67H116c-1,4.11-4.31,6.28-8.65,6.28-5,0-8.89-3-8.89-9.32,0-5.35,3.57-9.43,9.22-9.43s8.62,4.31,8.62,9.06A15.54,15.54,0,0,1,116.24,107.75Zm-12.36-3.31h7c-.3-2.5-1.47-3.81-3.4-3.81S104.31,101.8,103.88,104.44Z"
            transform="translate(-30.23 -90.61)"
          />
          <path
            class="svg-colour-text"
            d="M146.85,104.78V115.3h-5.24v-9.19a25.32,25.32,0,0,0-.14-2.7c-.2-1.78-1.13-2.78-2.8-2.78-2,0-3,1.77-3,5.42v9.25h-5.22v-9.19a25.24,25.24,0,0,0-.13-2.7c-.23-1.78-1.14-2.78-2.84-2.78-2,0-3,1.77-3,5.42v9.25h-5.25V97.49h5.25v2.77l1.33-2.2a6.56,6.56,0,0,1,3.85-1c3,0,4.81,1.47,5.54,3.44l1.81-2.4a6.4,6.4,0,0,1,3.81-1c3.47,0,5.44,2,5.85,4.45A23.17,23.17,0,0,1,146.85,104.78Z"
            transform="translate(-30.23 -90.61)"
          />
          <path
            class="svg-colour-text"
            d="M156,93.25a2.58,2.58,0,0,1-2.81,2.67A2.66,2.66,0,1,1,156,93.25Zm-5.41,22V97.49h5.24V115.3Z"
            transform="translate(-30.23 -90.61)"
          />
          <path
            class="svg-colour-text"
            d="M164.93,109.22c0,.77,0,1.17,0,1.64.1.8.46,1.07,2,1v3.58a30.34,30.34,0,0,1-3.14.16c-2.5,0-4-1-4.11-3.31-.06-.86-.06-1.73-.06-3V90.74h5.24Z"
            transform="translate(-30.23 -90.61)"
          />
          <path
            class="svg-colour-text"
            d="M180.14,115.67c-2,5.18-4,6.65-7.85,6.65a15.85,15.85,0,0,1-2.28-.13v-3.51a11.56,11.56,0,0,0,1.47.06,3.07,3.07,0,0,0,3.18-2.07l.23-.57-7.32-18.61h5.85l4.08,13.77,4-13.77h5.72Z"
            transform="translate(-30.23 -90.61)"
          />
          <path
            class="svg-colour-text"
            d="M188.66,100.23l-.47-9.49h4.51l-.5,9.49Z"
            transform="translate(-30.23 -90.61)"
          />
          <path
            class="svg-colour-text"
            d="M210.08,102.67H204.9c-.3-1.47-1.07-2.31-2.37-2.31a1.66,1.66,0,0,0-1.84,1.64c0,1.21,1,1.87,4.14,2.68,3.45.86,5.65,2.3,5.65,5.64,0,3.71-3.37,5.45-7.55,5.45-4.54,0-7.72-1.94-8.19-6h5.18a3,3,0,0,0,3,2.57c1.27,0,2.28-.53,2.28-1.67s-1.14-2-4.28-2.7c-3.34-.84-5.51-2.34-5.51-5.38,0-3.48,2.94-5.62,7.41-5.62C207.17,97,209.65,99.43,210.08,102.67Z"
            transform="translate(-30.23 -90.61)"
          />
          <!-- websites -->
          <path
            class="svg-colour-text"
            d="M121.32,146.06h-5.88l-2.67-12.93-2.71,12.93h-5.92L98.5,128.25h5.81l2.77,13.44,2.58-13.34h6.25l2.7,13.37,2.71-13.47H127Z"
            transform="translate(-30.23 -90.61)"
          />
          <path
            class="svg-colour-text"
            d="M145.42,138.51h-12.5c.3,3.41,1.77,4.41,3.74,4.41a3.16,3.16,0,0,0,3.38-2.67h5.18c-1,4.11-4.32,6.28-8.66,6.28-5,0-8.89-3-8.89-9.32,0-5.35,3.58-9.42,9.22-9.42s8.63,4.31,8.63,9C145.52,137.31,145.48,137.88,145.42,138.51Zm-12.37-3.31h7c-.3-2.5-1.47-3.81-3.41-3.81S133.49,132.56,133.05,135.2Z"
            transform="translate(-30.23 -90.61)"
          />
          <path
            class="svg-colour-text"
            d="M167.47,136.77c0,6.42-3.91,9.76-8.49,9.76a9,9,0,0,1-4.14-.93l-1.17-1.88v2.34h-5.25V121.5h5.25v9.43l1.34-2a8,8,0,0,1,4.58-1.17C164.5,127.79,167.47,131.53,167.47,136.77Zm-5.38.34c0-3.51-1.7-5.72-4.17-5.72s-4.25,1.47-4.25,5.55v.8c0,3.85,2.07,5.18,4.08,5.18C160.29,142.92,162.09,141,162.09,137.11Z"
            transform="translate(-30.23 -90.61)"
          />
          <path
            class="svg-colour-text"
            d="M184.35,133.43h-5.18c-.3-1.47-1.07-2.3-2.37-2.3a1.65,1.65,0,0,0-1.84,1.63c0,1.21,1,1.88,4.14,2.68,3.44.87,5.65,2.3,5.65,5.65,0,3.7-3.37,5.44-7.55,5.44-4.55,0-7.72-1.94-8.19-5.95h5.18a3,3,0,0,0,3,2.58c1.27,0,2.27-.54,2.27-1.67s-1.13-2-4.27-2.71c-3.35-.84-5.52-2.34-5.52-5.38,0-3.48,2.94-5.61,7.42-5.61C181.44,127.79,183.92,130.19,184.35,133.43Z"
            transform="translate(-30.23 -90.61)"
          />
          <path
            class="svg-colour-text"
            d="M192.8,124a2.79,2.79,0,1,1-2.8-2.64A2.57,2.57,0,0,1,192.8,124Zm-5.41,22V128.25h5.25v17.81Z"
            transform="translate(-30.23 -90.61)"
          />
          <path
            class="svg-colour-text"
            d="M202.9,139.21c0,.61,0,1.21,0,1.67.13,1.78.74,2.18,3.41,2v3.34a20.23,20.23,0,0,1-3.24.27c-3.91,0-5.18-1.27-5.38-3.88-.07-.9-.07-1.7-.07-2.7v-8.19h-2.71v-3.34h2.71v-4.48h5.25v4.48h3.77v3.34H202.9Z"
            transform="translate(-30.23 -90.61)"
          />
          <path
            class="svg-colour-text"
            d="M225.62,138.51h-12.5c.3,3.41,1.77,4.41,3.75,4.41a3.15,3.15,0,0,0,3.37-2.67h5.18c-1,4.11-4.31,6.28-8.65,6.28-5,0-8.89-3-8.89-9.32,0-5.35,3.57-9.42,9.22-9.42s8.62,4.31,8.62,9A15.4,15.4,0,0,1,225.62,138.51Zm-12.36-3.31h7c-.3-2.5-1.47-3.81-3.4-3.81S213.69,132.56,213.26,135.2Z"
            transform="translate(-30.23 -90.61)"
          />
          <path
            class="svg-colour-text"
            d="M242.67,133.43h-5.18c-.3-1.47-1.07-2.3-2.38-2.3a1.65,1.65,0,0,0-1.83,1.63c0,1.21,1,1.88,4.14,2.68,3.44.87,5.65,2.3,5.65,5.65,0,3.7-3.38,5.44-7.56,5.44-4.54,0-7.72-1.94-8.18-5.95h5.18a3,3,0,0,0,3,2.58c1.27,0,2.27-.54,2.27-1.67s-1.14-2-4.28-2.71c-3.34-.84-5.51-2.34-5.51-5.38,0-3.48,2.94-5.61,7.42-5.61C239.76,127.79,242.23,130.19,242.67,133.43Z"
            transform="translate(-30.23 -90.61)"
          />
        </g>
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  name: "ew-footer",
};
</script>

<style scoped lang="scss">
div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

span {
  min-width: fit-content;
  margin: 0.5em;
}

a {
  margin: 0.5em;
  height: 2em;
  color: #ddd;
}

svg {
  height: 100%;
}

// SVG logo colours
.svg-colour-text {
  fill: currentColor;
}

.svg-colour-rectangle {
  fill: currentColor;
}

.svg-colour-image {
  fill: #000;
}
</style>